<template>
  <div class="container mt-5">
    <h2>Sign up</h2>
    <form @submit.prevent="login">
      <div class="mb-3 col-md-6">
        <label for="userId" class="form-label">Username</label>
        <input type="text" v-model="username" class="form-control" id="userId" required />
      </div>
      <div class="mb-3 col-md-6">
        <label for="userId" class="form-label">Name</label>
        <input type="text" v-model="name" class="form-control" id="userId" required />
      </div>
      <div class="mb-3 col-md-6">
        <label for="userId" class="form-label">Email</label>
        <input type="text" v-model="email" class="form-control" id="userId" required />
      </div>
      <div class="mb-3 col-md-6">
        <label for="userId" class="form-label">Phone number</label>
        <input type="text" v-model="phoneNumber" class="form-control" id="userId" required />
      </div>
      <div class="mb-3 col-md-6">
        <label for="password" class="form-label">Password</label>
        <input type="password" v-model="password" class="form-control" id="password" required />
      </div>
      <button type="submit" class="btn btn-primary">Sign up</button>
      <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { BASE_URL } from '@/globals'

export default {
  name: 'SignupPage',
  data() {
    return {
      username: '',
      name: '',
      email: '',
      phoneNumber: '',
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    async login() {
      try {
        const body = { 
          username: this.username,
          name: this.name,
          email: this.email,
          phoneNumber: this.phoneNumber,
          password: this.password 
        }
        const response = await axios.post(`${BASE_URL}/api/auth/register`, body);
        localStorage.setItem('token', response.data.token);
        this.$router.push('/');
      } catch (error) {
        this.errorMessage = 'Login failed. Please check your credentials.';
      }
    },
  },
};
</script>
