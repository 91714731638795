<template>
  <div class="container mt-5">
    <!-- User Information Section -->
    <h2>{{ user.name }}'s Profile</h2>
    <p>Email: {{ user.email }}</p>
    <p>Username: {{ user.username }}</p>

    <!-- User's Posts Section -->
    <div class="user-posts mt-5">
      <h3>Your Posts</h3>
      <div v-if="!posts.length">
        <p>You have no posts yet.</p>
      </div>
      <div v-else class="row">
        <div class="col-md-3 mb-4" v-for="post in posts" :key="post._id">
          <div class="card shadow-sm mb-3 mt-2">
            <img
              v-if="post.photo"
              :src="post.photo"
              alt="Post Image"
              class="card-img-top"
            />
            <div class="card-body">
              <h5 class="card-title">{{ post.title }}</h5>
              <p class="card-text text-truncate">{{ post.desc }}</p>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item"><strong>Price:</strong> ${{ post.price }}</li>
                </ul>
                <hr/>
                <router-link :to="{ name: 'RoomDetails', params: { id: post._id } }" class="btn btn-primary btn-sm">
                  View Details
                </router-link>
              <!-- <router-link :to="'/edit/' + post._id" class="btn btn-warning me-2">
                Edit
              </router-link>
              <button @click="deletePost(post._id)" class="btn btn-danger">
                Delete
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { BASE_URL } from '@/globals'

export default {
  name: "MyProfile",
  data() {
    return {
      user: {
        name: '',
        email: '',
        username: '',
        phone: '',
      },
      posts: [], // User's posts
    };
  },
  methods: {
    fetchUserProfile() {
      const userHandle = localStorage.getItem('username')
      const token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${token}`
      }
      axios.get(`${BASE_URL}/api/users/${userHandle}`, { headers }).then(response => {
        this.user = response.data;
      });
    },
    fetchUserPosts() {
      const userHandle = localStorage.getItem('username')
      axios.get(`${BASE_URL}/api/posts?user=${userHandle}`).then(response => {
        this.posts = response.data;
      });
    },
    deletePost(postId) {
      postId
      // if (confirm('Are you sure you want to delete this post?')) {
      //   // Simulate deleting a post
      //   // Replace with actual API call
      //   axios.delete(`/api/posts/${postId}`).then(response => {
      //     // Remove the post from the list of posts
      //     this.posts = this.posts.filter(post => post._id !== postId);
      //   }).catch(error => {
      //     console.error("Failed to delete post:", error);
      //   });
      // }
    }
  },
  mounted() {
    this.fetchUserProfile(); 
    this.fetchUserPosts();   
  }
};
</script>

<style scoped>
h2, h3 {
  margin-bottom: 20px;
}

.card {
  background-color: #f0f0f0;
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

/* .card-body {
  text-align: center;
}

.btn {
  margin: 5px 0;
} */
</style>
