<template>
  <div class="container mt-5">
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="Search rooms by title or description..."
        v-model="searchQuery"
        @keyup.enter="search"
      />
      <button class="btn btn-outline-primary" @click="search">Search</button>
    </div>
    <div class="row">
      <template v-if="posts.length">  
        <div v-for="(post, index) in posts" :key="index" class="col-md-3 mb-4">
          <div class="card">
            <img v-if="post.photo" :src="post.photo" class="card-img-top" alt="Property Image">
            <div class="card-body">
              <h5 class="card-title">{{ post.title }}</h5>
              <p class="card-text">{{ post.desc }}</p>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"><strong>Bedrooms:</strong> {{ post.numOfBedrooms }}</li>
                <li class="list-group-item"><strong>Furnished:</strong> {{ post.furnsihed ? 'Yes' : 'No' }}</li>
                <li class="list-group-item"><strong>Parking:</strong> {{ post.parking ? 'Yes' : 'No' }}</li>
                <li class="list-group-item"><strong>Available From:</strong> {{ post.availableFrom }}</li>
                <li class="list-group-item"><strong>Price:</strong> ${{ post.price }}</li>
              </ul>
              <div class="card-footer d-flex justify-content-center align-items-center mt-2">
                <small class="text-muted me-2">Posted by {{ post.username }}</small>
                <!-- View Details Button -->
                <router-link :to="{ name: 'RoomDetails', params: { id: post._id } }" class="btn btn-primary btn-sm">
                  View Details
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="text-center mt-5">
        <p>No rental posts available.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { BASE_URL } from '@/globals'

export default {
  data() {
    return {
      posts: [],
      searchQuery: "",
    };
  },
  mounted() {
    this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      try {
        const response = await axios.get(`${BASE_URL}/api/posts`);
        this.posts = response.data;
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    },
    search() {
      if (this.searchQuery === "") {
        this.fetchPosts();
      } else {
        axios
          .get(`${BASE_URL}/api/posts?q=${this.searchQuery}`)
          .then((response) => {
            this.posts = response.data;
          })
          .catch((error) => {
            console.error("Error searching for rooms:", error);
          });
      }
    },
  },
};
</script>

<style scoped>
.card {
  min-height: 100%;
}

.card-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Webkit-specific line clamp */
  line-clamp: 3; /* Standard line-clamp property */
  -webkit-box-orient: vertical;
  box-orient: vertical; /* Standard property for orientation */
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em; /* Adjust based on font-size and line-height */
}

.card-img-top {
  width: 100%; /* Make the image take up the full width of the card */
  height: 200px; /* Set a fixed height for all images */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

</style>
