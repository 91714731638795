import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '../components/LoginPage.vue';
import RoomList from '@/components/RoomList.vue';
import RoomDetails from '@/components/RoomDetails.vue';
import SignupPage from '@/components/SignupPage.vue';
import MyProfile from '@/components/MyProfile.vue';
import AddRoom from '@/components/addRoom.vue';

const routes = [
  { path: '/', component: RoomList },
  { path: '/room/:id', name: 'RoomDetails', component: RoomDetails },
  { path: '/login', component: LoginPage },
  { path: '/signup', component: SignupPage },
  { path: '/profile', component: MyProfile, meta: { requiresAuth: true }  },
  { path: '/add', component: AddRoom, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guard for Auth
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token');
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
