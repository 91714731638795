<template>
  <div class="container mt-5">
    <div v-if="post">
      <div class="row">
        <div class="col-md-5 card d-flex justify-content-center">
          <img v-if="post.photo" :src="post.photo" class="card-img-top" alt="Property Image">
        </div>
        <div class="col-md-7">
          <div class="card-body">
            <h2 class="card-title mb-2">{{ post.title }}</h2>
            <p class="card-text">{{ post.desc }}</p>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><strong>Area:</strong> {{ post.area }}</li>
              <li class="list-group-item"><strong>Bedrooms:</strong> {{ post.numOfBedrooms }}</li>
              <li class="list-group-item"><strong>Furnished:</strong> {{ post.furnsihed ? 'Yes' : 'No' }}</li>
              <li class="list-group-item"><strong>Parking:</strong> {{ post.parking ? 'Yes' : 'No' }}</li>
              <li class="list-group-item"><strong>Available From:</strong> {{ post.availableFrom }}</li>
              <li class="list-group-item"><strong>Price:</strong> ${{ post.price }}</li>
              <li class="list-group-item"><strong>Contact:</strong> +{{ post.contact }}</li>
            </ul>
            <div class="card-footer d-flex justify-content-start align-items-center mt-2">
              <small class="text-muted me-2">Posted by {{ post.username }}</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Recent Posts Section -->
      <div class="recent-posts mt-5">
        <h3>Recent Posts</h3>
        <div class="row">
          <div class="col-md-3" v-for="recentPost in recentPosts" :key="recentPost._id">
            <div class="card shadow-sm mb-3 mt-2">
              <img
                v-if="recentPost.photo"
                :src="recentPost.photo"
                alt="Recent Post Image"
                class="card-img-top"
              />
              <div class="card-body">
                <h5 class="card-title">{{ recentPost.title }}</h5>
                <p class="card-text text-truncate">{{ recentPost.desc }}</p>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item"><strong>Price:</strong> ${{ post.price }}</li>
                </ul>
                <hr/>
                <p class="custom-link btn btn-primary" @click="fetchPostDetails(recentPost._id)">View Details</p>
              </div>
            </div>
          </div>
        </div>
        </div>

    </div>
    <div v-else class="text-center mt-5">
      <p>Loading post details...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { BASE_URL } from '@/globals'

export default {
  name: 'RoomDetails',
  data() {
    return {
      post: null,
      recentPosts: []
    };
  },
  mounted() {
    this.fetchPostDetails();
    this.fetchRecentPosts();
  },
  methods: {
    async fetchPostDetails(id) {
      const postId = id || this.$route.params.id;
      try {
        const response = await axios.get(`${BASE_URL}/api/posts/${postId}`);
        this.post = response.data;
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    },
    async fetchRecentPosts() {
      try {
        const response = await axios.get(`${BASE_URL}/api/posts?limit=5`);
        this.recentPosts = response.data;
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    },
  },
};
</script>

<style scoped>

.card {
  background-color: #f0f0f0;
}
.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

</style>
