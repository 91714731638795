<template>
  <div class="container mt-5 mb-5">
    <h2>Create an ad</h2>
    <form @submit.prevent="addPost">
      <div class="mb-3">
        <label for="title" class="form-label">Title</label>
        <input type="text" class="form-control" id="title" v-model="post.title" required />
      </div>
      <div class="mb-3">
        <label for="desc" class="form-label">Description</label>
        <textarea class="form-control" id="desc" v-model="post.desc" rows="3" required></textarea>
      </div>
      <div class="mb-3">
        <label for="photo" class="form-label">Image URL</label>
        <input type="text" class="form-control" id="photo" v-model="post.photo" />
      </div>
      <div class="mb-3">
        <label for="area" class="form-label">Address</label>
        <input type="text" class="form-control" id="area" v-model="post.area" required />
      </div>
      <div class="mb-3">
        <label for="numOfBedrooms" class="form-label">Number of Bedrooms</label>
        <input type="number" class="form-control" id="numOfBedrooms" v-model="post.numOfBedrooms" required />
      </div>
      <div class="mb-3">
        <label class="form-label">Furnished</label>
        <select class="form-select" v-model="post.furnished" required>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">Parking Available</label>
        <select class="form-select" v-model="post.parking" required>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="price" class="form-label">Price</label>
        <input type="number" class="form-control" id="price" v-model="post.price" required />
      </div>
      <div class="mb-3">
        <label for="price" class="form-label">Contact number</label>
        <input type="text" class="form-control" id="contact" v-model="post.contact" required />
      </div>
      <div class="mb-3">
        <label for="availableFrom" class="form-label">Available From</label>
        <input type="date" class="form-control" id="availableFrom" v-model="post.availableFrom" required />
      </div>
      <button type="submit" class="btn btn-primary">Add Post</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { BASE_URL } from '@/globals'

export default {
  name: "AddPost",
  data() {
    return {
      post: {
        title: '',
        desc: '',
        photo: '',
        username: localStorage.getItem('username'),
        area: '',
        numOfBedrooms: null,
        furnished: false,
        parking: false,
        availableFrom: '',
        price: null,
        contact: ''
      }
    };
  },
  methods: {
    addPost() {
      const token = localStorage.getItem('token'); // Get the token for authorization
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Send POST request to add the new post
      axios.post(`${BASE_URL}/api/posts`, this.post, { headers })
        .then(response => {
          console.log("Post added:", response.data);
          this.$router.push('/'); // Redirect to room list after adding
        })
        .catch(error => {
          console.error("Error adding post:", error);
        });
    }
  }
};
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>
