<template>
  <div class="container mt-5">
    <h2>Login</h2>
    <form @submit.prevent="login">
      <div class="mb-3 col-md-6">
        <label for="userId" class="form-label">Username</label>
        <input type="text" v-model="username" class="form-control" id="userId" required />
      </div>
      <div class="mb-3 col-md-6">
        <label for="password" class="form-label">Password</label>
        <input type="password" v-model="password" class="form-control" id="password" required />
      </div>
      <button type="submit" class="btn btn-primary">Login</button>
      <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { BASE_URL } from '@/globals'

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(`${BASE_URL}/api/auth/login`, { username: this.username, password: this.password });
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', this.username);
        this.$router.push('/').then(() => {
          window.location.reload(); // Reloads the entire application
        });
      } catch (error) {
        this.errorMessage = 'Login failed. Please check your credentials.';
      }
    },
  },
};
</script>
